// import $ from 'jquery';
// import Swiper from 'swiper/bundle';
import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Keyboard, Autoplay, Grid } from 'swiper';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/keyboard';
import 'swiper/css/grid';
// import "swiper/css/thumbs";

const config = {
  // configure Swiper to use modules
  modules: [Navigation, Pagination, Thumbs, EffectFade, Keyboard, Autoplay, Grid],
  pagination: {
    // el: '.swiper-pagination',
    el: null,
    clickable: true,
  },
  navigation: {
    // nextEl: '.swiper-button-next',
    // prevEl: '.swiper-button-prev',
    // nextEl: '.ib-swiper-button-next',
    // prevEl: '.ib-swiper-button-prev',
    nextEl: null,
    prevEl: null,
  },
  loop: true,
  on: {
    /* init: function () {
      var swiper = this;
      // fix for duplicates on loop
      if (swiper.originalParams.loop && swiper.loopedSlides < swiper.originalParams.slidesPerView) {
        // swiper.params.slidesPerView = swiper.loopedSlides;
        // swiper.destroy(false, false);
        // swiper.init();
        // swiper.allowSlideNext = swiper.allowSlidePrev = swiper.allowTouchMove = false
        // swiper.loopDestroy();
        // console.log(swiper);
        $('.swiper-slide-duplicate', swiper.$wrapperEl).hide();
      }
    }, */
    /* init: function () {
      var swiper = this;
      if (swiper.loopedSlides <= 1) {
        swiper.disable();
      }
    } */
  },
};

const isObject = function(obj) {
  var type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
};

export default class SwiperImplementation {
  init(node = '.swiper', custom_config = {}, forceInit = false) {
    const settings = $.extend(true, {}, config);
    Object.assign(settings, custom_config);

    if (isObject(settings.pagination)) {
      if ('el' in settings.pagination && settings.pagination.el == null) {
        settings.pagination.el = `${node} .swiper-pagination`;
      }
    }
    if (isObject(settings.navigation)) {
      if ('nextEl' in settings.navigation && settings.navigation.nextEl == null) {
        settings.navigation.nextEl = `${node} .ib-swiper-button-next`;
      }
      if ('prevEl' in settings.navigation && settings.navigation.prevEl == null) {
        settings.navigation.prevEl = `${node} .ib-swiper-button-prev`;
      }
    }

    // console.log(settings);
    if ($('.swiper-slide', node).length > 1 || forceInit) {
      const swiper = new Swiper(node, settings);
      // $('> .d-none', node).removeClass('d-none');
      return swiper;
    }
    return null;
  };
}